<template>
  <v-footer fixed inset height="60" class="pa-3">
    <v-spacer></v-spacer>
    <p class="message" v-if="message">{{ message }}</p>
    <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>

    <template v-if="status === 'saving'">
      <v-btn
        class="save-status"
        :disabled="disabled"
        color="accent"
        type="button"
        depressed
        @click="buttonClick()"
      >

        <v-img
          :src="require('../../assets/gamechanger-bolt.svg')"
          class="saving__icon pulse"
          contain
          height="15"
          width="15"
        ></v-img>

        Saving
      </v-btn>
    </template>

    <template v-else-if="status === 'saved'">
      <v-btn
        class="save-status"
        :disabled="disabled"
        color="#5EA77E"
        dark
        type="button"
        depressed
        @click="buttonClick()"
      >
        <v-icon class="saving__icon" small>check</v-icon>

        Saved
      </v-btn>
    </template>

    <template v-else>
      <v-btn
        v-if="secondaryButtonText"
        class="save-status save-status--secondary"
        :disabled="disabled"
        color="accent"
        type="button"
        depressed
        @click="secondaryButtonClick()"
      >
        {{ secondaryButtonText }}
      </v-btn>

      <v-btn
        class="save-status"
        :disabled="disabled"
        color="accent"
        type="button"
        depressed
        @click="buttonClick()"
      >
        {{ buttonText }}
      </v-btn>
    </template>

    <v-btn v-if="allowPreview" @click="preview()">Preview</v-btn>

  </v-footer>
</template>

<script>
export default {
  name: "StickyFooter",

  props: {
    buttonText: {
      type: String,
      default: "Save"
    },
    secondaryButtonText: {
      type: String,
      required: false
    },
    allowPreview: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      required: false
    },
    errorMessage: {
      type: String,
      required: false
    },
    status: {
      required: false,
      validator: function (value) {
        // The value must match one of these strings
        return ["saved", "saving", "needsSaving"].indexOf(value) !== -1
      }
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },

  methods: {
    buttonClick() {
      console.log(this.buttonText);
      this.$emit("buttonClicked");
    },

    secondaryButtonClick() {
      console.log(this.secondaryButtonText);
      this.$emit("secondaryButtonClicked");
    },

    preview() {
      console.log("Previewing post");
      this.$emit("previewRequested");
    }
  }
}
</script>

<style lang="scss" scoped>
  .error-message {
    margin: 0 10px;
    color: #d61244;
    font-size: 14px;
  }

  .save-status {
    text-transform: none;

    &--secondary {
      padding: 8px;
      font-size: 10px;
      opacity: 0.75;
    }
  }
  .saving__icon {
    margin: 0 10px 0 0;

    &.pulse {
      animation-duration: 0.6s;
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }

    30% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }
</style>
