<template>
    <div class="mini-header">
        <h2 class="subheading">{{ heading }}</h2>

        <v-btn
            v-if="hasButton"
            class="mini-header__button"
            color="accent"
            type="button"
            depressed
            @click="buttonClick()"
        >
            <v-icon small>add</v-icon>
            {{ buttonText }}
        </v-btn>
    </div>
</template>

<script>
export default {
    name: "MiniHeader",

    props: {
        heading: {
            type: String,
            required: true
        },
        buttonText: {
            type: String,
            required: false
        },
        hasButton: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        buttonClick() {
            this.$emit("buttonClicked");
        }
    }

}
</script>

<style lang="scss" scoped>
.mini-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px;

    .subheading {
        margin: 0;
    }
}
</style>
