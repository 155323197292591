<template>
  <section class="main-body">
    <MiniHeader :heading="''" :buttonText="'Add New'" :hasButton="true" @buttonClicked="openNewDialog()"/>

    <draggable
      v-if="links"
      v-model="links"
      :options="{
        ghostClass: 'ghost',
        dragClass: 'sortable-drag'
      }"
      @start="drag=true"
      @end="drag=false; status = 'needsSaving';">

      <transition-group name="list-complete" class="featured__items">

        <div v-for="(item, index) in links" class="featured__item draggable-item" :key="item.id">
          <div class="featured__item-content">
            <v-icon class="draggable-item__handle-icon">open_with</v-icon>
            <div class="featured__item-content-title">{{item.adminName || item.category || 'No name found'}}</div>

            <div class="featured__actions">
              <button class="featured__edit" type="button" @click="openEditDialog(index)">
                <v-icon>edit</v-icon>
              </button>
              <div
                class="featured__item-content-action"
                @click="removeLink(index)">
                <v-icon color="accent" right>remove_circle</v-icon>
              </div>
            </div>
          </div>
        </div>

      </transition-group>
    </draggable>

    <p v-if="!links.length && dataLoaded">No links added yet.</p>

    <v-dialog
      class="row-edit"
      v-model="dialog"
      width="580"
      scrollable
      lazy>
      <v-card>
        <v-card-title class="card-title" v-if="editing">Edit link</v-card-title>
        <v-card-title class="card-title" v-else>Add a new link</v-card-title>

        <v-card-text>
          <div>
            <label class="label" for="admin-name">Admin name (required)</label>
            <v-text-field
              id="admin-name"
              v-model="linkData.adminName"
              solo
              flat
              required
            ></v-text-field>
          </div>
  
          <div>
            <label class="label" for="category">Category</label>
            <v-text-field
              id="category"
              v-model="linkData.category"
              solo
              flat
            ></v-text-field>
          </div>

          <div>
            <label class="label" for="link">URL (recommended)</label>
            <v-text-field
              id="link"
              v-model="linkData.link"
              solo
              flat
            ></v-text-field>
          </div>

          <div>
            <label>Display as Hero?</label>
            <v-select
              v-model="linkData.hero"
              item-text='name'
              item-value='value'
              :items="[
                { name: 'Display as Hero if first item', value: true },
                { name: 'Not Hero', value: false }
              ]"
              solo
              flat
              @change="status = 'needsSaving'"/>
          </div>

          <ImageUploader v-if="dialog" :title="'Image'" :widthOptionEnabled="false" :mediaLibraryID="linkData.mediaLibraryID" @update="updateImage($event)" :desc="'<p>Your image should be 16:9 to ensure no cropping will take place</p>'"/>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="editing"
            color="accent"
            outline
            flat
            :disabled="!linkData.adminName"
            @click="editLink()"
          >
            Edit link
          </v-btn>
          <v-btn
            v-else
            color="accent"
            outline
            flat
            :disabled="!linkData.adminName"
            @click="addLink()"
          >
            Add link
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <StickyFooter :errorMessage="errorMessage" :status="status" :buttonText="'Save'" @buttonClicked="saveData()" />
  </section>
</template>

<script>
import axios from "axios"
import draggable from "vuedraggable"
import MiniHeader from "@/components/Library/MiniHeader"
import StickyFooter from "@/components/Library/StickyFooter"
import ImageUploader from "@/components/Library/ImageUploader"

export default {
  name: "EcommerceLinksManager",

  components: {
    draggable,
    MiniHeader,
    StickyFooter,
    ImageUploader
  },

  data: () => ({
    api: `${process.env.VUE_APP_APPCONTENT}/ecommercelinks`,
    dialog: false,
    editing: false,
    editingIndex: null,
    status: "saved",
    errorMessage: "",
    dataLoaded: false,
    linkData: {
      id: "",
      adminName: "",
      category: "",
      link: "",
      mediaLibraryID: "",
      imageKey: "",
      hero: false
    },
    links: []
  }),

  mounted() {
    this.fetchData()
  },

  methods: {
    fetchData() {
      this.$store.commit("startLoading");
      this.status = "saving";

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.api}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("fetchData: ", response);
          
          this.links = response.data.body;

          this.dataLoaded = true

          this.status = "saved";
          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem retrieving rover data", e);
          this.$store.commit("completeLoading");
        });
      })
    },

    saveData() {
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}`,
          headers: { "Authorization": jwt },
          data: this.links
        })
        .then(response => {
          console.log("Data updated", response)
          this.fetchData()
        })
        .catch(e => {
          console.error("Problem updating app data", e);
          this.status = "needsSaving";
          this.errorMessage = "Problem updating data";
          this.$store.commit("completeLoading");
        });
      })
    },

    openNewDialog() {
      this.resetData()
      this.editing = false
      this.editingIndex = null
      this.dialog = true
    },

    openEditDialog(index) {
      this.editing = true
      this.editingIndex = index
      this.dialog = true

      this.linkData = JSON.parse(JSON.stringify(this.links[this.editingIndex]))
    },

    addLink() {
      this.links.unshift({
        id: this.$uuid.v1(),
        adminName: this.linkData.adminName,
        category: this.linkData.category ? this.linkData.category : null,
        link: this.linkData.link,
        mediaLibraryID: this.linkData.mediaLibraryID ? this.linkData.mediaLibraryID : null,
        imageKey: this.linkData.imageKey ? this.linkData.imageKey : null,
        hero: this.linkData.hero
      })

      this.status = "needsSaving";
      this.dialog = false
      this.resetData()
    },

    editLink() {
      this.links[this.editingIndex] = {
        id: this.linkData.id,
        adminName: this.linkData.adminName,
        category: this.linkData.category ? this.linkData.category : null,
        link: this.linkData.link,
        mediaLibraryID: this.linkData.mediaLibraryID ? this.linkData.mediaLibraryID : null,
        imageKey: this.linkData.imageKey ? this.linkData.imageKey : null,
        hero: this.linkData.hero
      }

      this.status = "needsSaving";
      this.editing = false
      this.editingIndex = null
      this.dialog = false
      this.resetData()
    },

    removeLink(index) {
      if (confirm("Are you sure you want to delete this item?")) {
        const location = this.links;

        location.splice(index, 1);
        this.status = "needsSaving";
      }
    },

    resetData() {
      this.linkData = {
        id: "",
        adminName: "",
        category: "",
        link: "",
        mediaLibraryID: "",
        imageKey: "",
        hero: false
      }
    },

    updateImage(payload) {
      console.log("updateImage", payload)
      if (payload.mediaLibraryID) {
        this.linkData.mediaLibraryID = payload.mediaLibraryID
        this.linkData.imageKey = payload.imageKey
      } else {
        this.linkData.mediaLibraryID = null
        this.linkData.imageKey = null
      }
    },
  }
}
</script>

<style scoped lang="scss">

  .draggable-item {
    transition: all 0.5s, opacity 0.2s;

    &__handle-icon {
      cursor: pointer;
      padding-right: 20px;
    }
  }

  .featured {
    &__subheading {
      margin-top: 0;
    }

    &__item {
      border: 1px solid rgba($purple, 0.7);
      background-color: rgba($purple, 0.1);
      border-radius: 3px;
      margin-bottom: 20px;

      &-content {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &-title {
          color: $purple;
          font-size: 1.4rem;
        }

        &-action {
          cursor: pointer;
        }
      }
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin: 0 0 0 auto;
    }
  }

  .card-title {
    background-color: $purple;
    color: $lightergrey;
    font-size: 16px;
    font-weight: bold;
  }

</style>
